import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, IconButton, Stack, styled, Switch, Typography } from '@mui/material';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useSettings } from 'src/hooks/useSettings';

import Logo from '../../../components/logo';
import { NavSectionVertical } from '../../../components/nav-section';
import { NAV } from '../../../config-global';

import navConfig from './config-navigation';
import NavAccount from './NavAccount';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const { themeMode, onChangeMode } = useSettings();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack
          spacing={1}
          sx={{
            pt: 2,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            gap: 2,
          }}
        >
          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
            <Logo />
            <IconButton onClick={onCloseNav}>
              {/* <Iconify icon="material-symbols:menu-open-rounded" /> */}
              <Iconify icon={'eva:menu-2-outline'} />
            </IconButton>
          </Stack>

          <NavAccount />

          {/* <SearchMenu /> */}
        </Stack>

        <NavSectionVertical data={navConfig.filter((v) => v.isMobileAccessible)} />
        <Stack alignItems={'flex-end'} justifySelf={'flex-end'} m={2}>
          <MaterialUISwitch
            checked={themeMode !== 'light'}
            onChange={() => {
              const val = themeMode === 'light' ? 'dark' : 'light';
              onChangeMode({
                target: { value: val },
              } as unknown as React.ChangeEvent<HTMLInputElement>);
            }}
          />
        </Stack>
      </Box>

      {/* <IconButton
        onClick={() => {
          const val = themeMode === 'light' ? 'dark' : 'light';
          onChangeMode({
            target: { value: val },
          } as unknown as React.ChangeEvent<HTMLInputElement>);
        }}
        sx={{ mt: 2 }}
      >
        {themeMode === 'light' ? (
          <NightlightOutlinedIcon sx={{ rotate: '-45deg' }} />
        ) : (
          <LightModeOutlinedIcon />
        )}
      </IconButton> */}

      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Scrollbar>
  );

  return (
    // <Box
    //   component="nav"
    //   sx={{
    //     flexShrink: { lg: 0 },
    //     width: { lg: NAV.W_DASHBOARD },
    //   }}
    // >
    // <NavToggleButton />

    //  {isDesktop ? (
    //   <Drawer
    //     open
    //     variant="permanent"
    //     PaperProps={{
    //       sx: {
    //         zIndex: 0,
    //         width: NAV.W_DASHBOARD,
    //         bgcolor: 'transparent',
    //         borderRightStyle: 'dashed',
    //       },
    //     }}
    //   >
    //     {renderContent}
    //   </Drawer>
    // ) : (
    <Drawer
      open={openNav}
      onClose={onCloseNav}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: NAV.W_DASHBOARD,
        },
      }}
    >
      {renderContent}
      <Typography variant="caption" textAlign={'center'} mb={1}>
        © {moment().year()} INNERWAVE
      </Typography>
    </Drawer>
    //  )}
    // </Box>
  );
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main, // theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 0.5,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    // border: theme.palette.mode === 'dark' ? `1px solid #aab4be` : `1px solid #8796A5`,
    borderRadius: 20 / 2,
  },
}));
