import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Alert, Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useStores } from 'src/models';
import * as Yup from 'yup';

import FormProvider, { RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  userId: string;
  userPwd: string;
  afterSubmit?: string;
};

export default function LoginMobile() {
  const rootStore = useStores();
  const { authStore } = rootStore;
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    userId: Yup.string().required('아이디를 입력해주세요'),
    userPwd: Yup.string().required('비밀번호를 입력해주세요'),
  });

  const defaultValues = {
    userId: '',
    userPwd: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const loginSuccess = async () => {
    await authStore.getLoginUserInfo();
  };

  const onSubmit = async (data: FormValuesProps) => {
    const userId = getValues('userId') ?? '';
    const userPwd = getValues('userPwd') ?? '';

    if (!checkParam(userId, userPwd)) {
      return;
    }

    (async () => {
      authStore.loginProcess(userId, userPwd).then(() => {
        const loginResponse = authStore.loginResponse;
        if (loginResponse) {
          if (loginResponse.loginResult === 'SUCCESS') {
            if (loginResponse.expired === true) {
              setIsAlertOpen(true);
            } else {
              loginSuccess();
            }
          } else {
            const message = loginResponse.errorMessage;
            enqueueSnackbar(
              message ? message : '로그인에 실패하였습니다. 관리자에게 문의 바랍니다.',
              { variant: 'error' },
            );
          }
        } else {
          return;
        }
      });
    })();
  };

  const checkParam = (userId: string, pwd: string) => {
    if (userId.length === 0) {
      enqueueSnackbar('아이디를 입력하세요.', { variant: 'warning' });
      return false;
    }
    if (pwd.length === 0) {
      enqueueSnackbar('비밀번호를 입력하세요.', { variant: 'warning' });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const message = localStorage.getItem('messageInLoginPage') ?? '';
    if (message.length > 0) {
      enqueueSnackbar(message, { variant: 'info' });
      localStorage.removeItem('messageInLoginPage');
    }
  }, []);

  return (
    <>
      <Stack
        sx={{
          px: 2,
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
          mb: 30,
        }}
      >
        <Box component={'img'} src="assets/images/logo-color.svg" sx={{ width: '110px' }} />
        <Box>
          <Stack sx={{ alignItems: 'center', width: '100%' }}>
            <Stack direction={'row'}>
              <Typography variant="h2">Welcome Back</Typography>
            </Stack>
            <Typography variant="inherit" mb={3}>
              <strong>F</strong>ore
              <strong>C</strong>ast your <strong>O</strong>rder & <strong>S</strong>ales
            </Typography>
          </Stack>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ flex: 1, width: '100%' }}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField
                name="userId"
                label="Email address"
                variant={'outlined'}
                placeholder="이메일을 입력해 주세요."
              />

              <RHFTextField
                name="userPwd"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                variant={'outlined'}
                placeholder="비밀번호를 입력해 주세요."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <LoadingButton
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              loading={!!isSubmitting}
              sx={{
                mt: 5,
                bgcolor: 'primary.main',
                borderRadius: 1,
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
              }}
            >
              Login
            </LoadingButton>
          </FormProvider>
        </Box>
        <Typography variant="inherit">© {moment().year()} INNERWAVE</Typography>
      </Stack>
    </>
  );
}
